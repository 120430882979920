<template>
  <v-container fluid>
    <formDialog2
      :dialogStatus.sync="formDialogStatus"
      :editedData="editedItem"
      :indexEdited.sync="editedIndex"
      ref="refDialogForm"
      :loadData.sync="loadData"
    ></formDialog2>

    <v-expansion-panel class="mb-3" inset v-model="open">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout row wrap align-center>
          <v-flex class="mx-5">
            <v-layout class="mx-5" row wrap align-center>
              <v-autocomplete
                v-if="!ifCenterUser"
                class="ma-2"
                v-model="searchParam.center_id"
                :item-text="
                  (item) => item.center_code + ' - ' + item.center_name
                "
                item-value="id"
                :items="getCenterItems"
                menu-props="auto"
                label="Select Center"
                prepend-icon="list"
              >
                <template v-slot:selection="data">
                  <v-chip
                    close
                    @input="searchParam.center_id = ''"
                    :selected="data.selected"
                    class="chip--select-multi"
                  >
                    {{ data.item.center_code + " - " + data.item.center_name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-tile-content
                      v-text="data.item"
                    ></v-list-tile-content>
                  </template>
                  <template v-else>
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="
                          data.item.center_code + ' - ' + data.item.center_name
                        "
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-text-field
                name="email"
                label="Email"
                v-model="searchParam.email"
                v-validate="'required'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                required
                prepend-icon="edit"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md12 class="mb-3">
            <v-layout justify-center row fill-height align-center>
              <v-btn outline round large @click="searchCenterUserList()">
                <v-icon left dark>search</v-icon>Search
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-card-text class="title">Department Users List</v-card-text>
          </v-card>
          <v-divider class="my-2" vertical></v-divider>
          <!-- <v-text-field
            class="mt-0"
            flat
            hide-details
            label="Search"
            prepend-inner-icon="search"
            single-line
            solo
            v-model="search"
            v-on:keyup.native.enter="getDataFromApi()"
          ></v-text-field>-->
          <!-- <v-btn @click="getDataFromApi()" fab small color="primary" dark>
            <v-icon dark>search</v-icon>
          </v-btn>-->
          <v-spacer />

          <v-card-actions>
            <v-btn color="primary" dark @click="newItem"
              >New Department User</v-btn
            >
          </v-card-actions>

          <!-- <v-btn fab small color="primary" class="white--text" @click="exportCenterUserListClick()">
            <v-icon dark>arrow_downward</v-icon>
          </v-btn>-->
        </v-layout>
      </v-card>
      <!-- {{getCenterUserList[0]}} -->
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="getCenterUserList"
        class="elevation-1"
        :total-items="pagination.totalItems"
        :pagination.sync="pagination"
        :rows-per-page-items="pagination.rowsPerPageItems"
      >
        <template v-slot:items="props">
          <td>{{ sl_count + props.index }}</td>
          <td class="text-xs">{{ props.item.center_name }}</td>
          <td class="text-xs">{{ props.item.name }}</td>
          <td class="text-xs">{{ props.item.username }}</td>
          <td class="text-xs">{{ props.item.email }}</td>

          <td
            class="justify-center text-xs-center"
            style="display: flex; padding: 0px"
          >
            <v-item-group>
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn fab small color="primary" v-on="on">
                    <v-icon
                      class="mr-0"
                      color="white"
                      dark
                      @click="sendMail(props.item)"
                      >mail</v-icon
                    >
                  </v-btn>
                </template>
                <span>Mail</span>
              </v-tooltip> -->
              <v-tooltip right color="green">
                <template v-slot:activator="{ on }">
                  <v-layout row justify-center>
                    <v-dialog v-model="dialog" persistent max-width="600px">
                      <template v-slot:activator="{ on }">
                        <v-btn fab small color="green" v-on="on">
                          <v-icon
                            @click="assignSubject(props.item)"
                            color="white"
                            >library_books</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Assign Subject</span>
                          <v-spacer></v-spacer>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="red white--text"
                            @click="closeDailoug()"
                            ><v-icon dark>close</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-card-text style="margin-top: -30px">
                          <v-container grid-list-md>
                            <!-- {{getAllSubjectData}} -->
                            <v-layout wrap>
                              <v-flex xs12 sm6 md12>
                                <v-autocomplete
                                  class="ma-2"
                                  v-model="searchParam.degree_code"
                                  :items="getSchema"
                                  :item-text="(item) => item.degree_name"
                                  item-value="degree_code"
                                  label="Select Degree"
                                  persistent-hint
                                  prepend-icon="library_books"
                                  @change="
                                    onDegreeSelect(searchParam.degree_code)
                                  "
                                >
                                  <template v-slot:selection="data">
                                    <v-chip
                                      color="#EBEBEB"
                                      close
                                      @input="searchParam.degree_code = ''"
                                      :selected="data.selected"
                                      class="chip--select-multi"
                                    >
                                      {{
                                        data.item.degree_code +
                                        " - " +
                                        data.item.degree_name
                                      }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <template
                                      v-if="typeof data.item !== 'object'"
                                    >
                                      <v-list-tile-content
                                        v-text="data.item"
                                      ></v-list-tile-content>
                                    </template>
                                    <template v-else>
                                      <v-list-tile-content>
                                        <v-list-tile-title
                                          v-html="
                                            data.item.degree_code +
                                            ' - ' +
                                            data.item.degree_name
                                          "
                                        ></v-list-tile-title>
                                      </v-list-tile-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex xs12 sm6 md12>
                                <v-autocomplete
                                  class="ma-2"
                                  v-model="searchParam.code"
                                  :items="getdegreeGorup"
                                  :item-text="(item) => item.title"
                                  item-value="code"
                                  label="Select Degree Group"
                                  persistent-hint
                                  prepend-icon="library_books"
                                  @change="
                                    onDegreeGroupSelect(searchParam.code)
                                  "
                                >
                                  <template v-slot:selection="data">
                                    <v-chip
                                      color="#EBEBEB"
                                      close
                                      @input="searchParam.code = ''"
                                      :selected="data.selected"
                                      class="chip--select-multi"
                                    >
                                      {{
                                        data.item.code + " - " + data.item.title
                                      }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <template
                                      v-if="typeof data.item !== 'object'"
                                    >
                                      <v-list-tile-content
                                        v-text="data.item"
                                      ></v-list-tile-content>
                                    </template>
                                    <template v-else>
                                      <v-list-tile-content>
                                        <v-list-tile-title
                                          v-html="
                                            data.item.code +
                                            ' - ' +
                                            data.item.title
                                          "
                                        ></v-list-tile-title>
                                      </v-list-tile-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex xs12 sm6 md12>
                                <v-autocomplete
                                  class="ma-2"
                                  v-model="searchParam.subject_code"
                                  :items="getAllSubjectData"
                                  :item-text="(item) => item.subject_name"
                                  item-value="subject_code"
                                  label="Select Subject"
                                  persistent-hint
                                  prepend-icon="library_books"
                                >
                                  <template v-slot:selection="data">
                                    <v-chip
                                      color="#EBEBEB"
                                      close
                                      @input="searchParam.subject_code = ''"
                                      :selected="data.selected"
                                      class="chip--select-multi"
                                    >
                                      {{
                                        data.item.subject_code +
                                        " - " +
                                        data.item.subject_name
                                      }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <template
                                      v-if="typeof data.item !== 'object'"
                                    >
                                      <v-list-tile-content
                                        v-text="data.item"
                                      ></v-list-tile-content>
                                    </template>
                                    <template v-else>
                                      <v-list-tile-content>
                                        <v-list-tile-title
                                          v-html="
                                            data.item.subject_code +
                                            ' - ' +
                                            data.item.subject_name
                                          "
                                        ></v-list-tile-title>
                                      </v-list-tile-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <!-- <v-flex xs12 sm6 md12>
                                <v-select
                                  :items="activity"
                                  label="Standard"
                                >
                                </v-select>
                              </v-flex> -->
                            </v-layout>
                          </v-container>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                          <v-btn
                            style="margin-bottom: 30px"
                            color="primary"
                            dark
                            @click="
                              SubjectAsign(
                                searchParam.degree_code,
                                searchParam.code,
                                searchParam.subject_code
                              )
                            "
                            >Assign</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                  <!-- <v-btn fab small color="green" v-on="on">
                    <v-icon @click="assignSubject(props.item)" color="white"
                      >library_books</v-icon
                    >
                  </v-btn> -->
                </template>
                <span>Assign Subject</span>
              </v-tooltip>
              <!-- <v-tooltip right color="red">
                <template v-slot:activator="{ on }">
                  <v-btn fab small color="red" v-on="on">
                    <v-icon @click="deleteItem(props.item)" color="white"
                      >delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip> -->
            </v-item-group>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn fab small color="primary" v-on="on">
                    <v-icon
                      @click="editItem(props.item)"
                      class="mr-0"
                      color="white"
                      >edit</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            <template>
              <div class="text-xs-center">
                <v-dialog v-model="subjectdialog" width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn fab small color="#1976D2" dark v-on="on">
                      <v-icon @click="viewDepartmentUserSubject(props.item)" color="white"
                        >visibility</v-icon
                      >
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="grey lighten-2" primary-title>
                      <p style="font-size:22px;">Subject List of <span style="font-size:26px;"> "{{name}}"</span></p>
                    </v-card-title>

                    <v-card-text>
                      <p v-for="item, index in getDepartmentuserDataListget" :key="index" style="display:flex;">
                          {{index+1}}. {{item.subject_name}}
                      </p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        @click="subjectdialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </template>
          </td>
        </template>
      </v-data-table>
      <composeMail
        :toMail.sync="emailList"
        :toSms.sync="smsList"
        :dialogStatus.sync="mailDialogStatus"
      ></composeMail>
    </v-container>
  </v-container>
</template>

<script>
import formDialog2 from "@/components/user/userForm.vue";
import composeMail from "@/components/utils/composeMail.vue";
import commonFun from "../../mixin/common_function";
import centerRegMixin from "../../mixin/center_reg_mixin";
import common_login_getters from "../../mixin/common_login_getters";
import commonGet from "@/mixin/common_getters_mixin";

export default {
  mixins: [commonFun, centerRegMixin, common_login_getters, commonGet],
  components: {
    formDialog2,
    composeMail,
  },
  data() {
    return {
      name:'',
      subjectdialog: false,
      user_id: "",
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [5, 10, 25, 50, 100, 200, 500, { text: "All", value: -1 }],
      },
      totalDataItems: 0,
      sl_count: 1,
      loading: true,
      toggle_exclusive: 0,
      mailDialogStatus: false,
      snackbar: false,
      multiLine: true,
      loadData: false,
      dialog: false,
      formDialogStatus: false,
      search: "",
      searchParam: {},
      // "name": "kh", "email": "kk@gmail.com", "username": "kk",
      activity: [0, 1],
      headers: [
        {
          text: "SL.",
          align: "left",
        },
        { text: "Center's Name", value: "center_name" },
        { text: "Center User's Name", value: "name" },
        { text: "Center User's Username", value: "username" },
        { text: "Center User's Email", value: "email" },
        { text: "Action", align: "center", sortable: false },
      ],
      editedIndex: -1,
      editedItem: this.unSetEditedData,
      emailList: [],
      smsList: [],
    };
  },
  watch: {
    mailDialogStatus(val) {
      console.log("mailDialogStatus", val);
    },
    pagination: {
      handler() {
        this.searchCenterUserList();
      },
      deep: true,
    },
    loadData: {
      handler(status) {
        if (status) this.searchCenterUserList();
        this.loadData = false;
      },
    },
  },
  created() {
    this.getdegree();
    if (!this.$store.getters.getCenterItems.length) {
      console.log("sssss");
      console.log("Center user from 2", this.ifCenterUser);
      this.$store.dispatch("fetchCenterData");
    }
    if (this.ifCenterUser) {
      console.log("Center user from 2", this.ifCenterUser);
      //this.searchParam.center_code = [this.getAuthUser.center.center_code]; //or
      this.searchParam.center_id = this.getAuthUser.center.id;
    }
  },
  mounted() {},
  computed: {
    getCenterUserList() {
      return this.$store.getters.getCenterUserItem;
    },
    getMsg() {
      return this.$store.getters.getCenterUserMessage;
    },
    getCenterItems() {
      return this.$store.getters.getCenterItems;
    },
    getSchema() {
      return this.$store.getters.getDegreeData;
    },
    getdegreeGorup() {
      return this.$store.getters.getDegreeGroupListData;
    },
    getAllSubjectData() {
      return this.$store.getters.getSubjectDataList;
    },
    getDepartmentuserDataListget(){
      return this.$store.getters.getDepartmentuserDataList;
    }
  },
  methods: {
    assignSubject(items) {
      this.user_id = items.id;
      console.log("Tanvir assign DATA------------------->", this.user_id);
    },
    getdegree() {
      this.$store.dispatch("fetchDegreeList");
    },
    onDegreeSelect(degreeGroup) {
      let degree_code = degreeGroup;
      console.log("Tanvir this is degree code----->", degree_code);
      this.$store.dispatch("fetchDegreeGroupListData", degree_code);
    },
    onDegreeGroupSelect(code) {
      let degree_group_code = code;
      console.log("Tanvir this is subject", degree_group_code);
      this.$store.dispatch("fetchSubjectListData", degree_group_code);
    },
    viewDepartmentUserSubject(props){
      console.log("Tanvir this is props of subject", props)
      this.name = props.name;
      let peram = {};
      peram.user_id = props.id;
      peram.exam_initiate_id = this.getExamInitiateId;
      this.$store.dispatch("getSubjectDepartment", peram);
    },
    SubjectAsign(degree, degreeGroup, Subject) {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.degree_code = degree;
      peram.degree_group_code = degreeGroup;
      peram.subject_code = Subject;
      peram.user_id = this.user_id;
      peram.active = 1;
      console.log("Tanvir this is Assign data", peram);
      this.$store.dispatch("postSubjectDepartment", peram);
    },
    closeDailoug() {
      this.dialog = false;
      this.searchParam.degree_code = "";
      this.searchParam.code = "";
      this.searchParam.subject_code = "";
    },
    editItem(item) {
      this.editedIndex = this.getCenterUserList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogOpen();
      console.log(" editItem ", this.editedItem);
    },
    setLimit() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.search = this.search;
    },
    // searchCenterUserList() {
    //   this.loading = true;
    //   this.setLimit();
    //   var self = this;
    //   this.$store
    //   .dispatch("callCenterUserData", {
    //     limit: this.limit,
    //     center_id_url: this.getCenterUrl()
    //   })
    //   .then(data => {
    //     this.loading = false;
    //     this.sl_count = data.meta.from;
    //     this.pagination.totalItems = data.meta.total;
    //   });
    // },
    searchCenterUserList() {
      this.loading = true;
      this.setLimit();
      this.searchParam.role_id = "103";
      console.log("This is department user", this.searchParam.role_id);
      this.$store
        .dispatch("searchCenterUserList", this.searchParam)
        .then((data) => {
          // console.log("data---", data);
          this.loading = false;
          this.sl_count = data[0].meta.from;
          this.pagination.totalItems = data[0].meta.total;
        });
    },
    sendMail(item = false) {
      this.smsList = [];
      this.emailList = [];
      if (item) {
        this.smsList.push(item.mobile);
        this.emailList.push(item.email);
      } else {
        this.getRegionalCenterList.forEach((item) => {
          this.smsList.push(item.mobile);
          this.emailList.push(item.email);
        });
      }
      console.log("tosms ", this.smsList);
      this.mailDialogStatus = !this.mailDialogStatus;
    },
    exportCenterUserListClick() {
      this.$store.dispatch("exportCenterUserList");
    },
    deleteItem(item) {
      const id = item.id;
      let self = this;
      this.$root
        .$confirm("Are you sure that you want to delete?")
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("deleteCenterUserItem", id)
              .then(function () {
                self.searchCenterUserList();
                // self.snackbar = true;
              })
              .catch(function (error) {
                console.log(error);
                self.searchCenterUserList();
              });
          }
        });
    },
    newItem() {
      this.unSetEditedData();
      this.dialogOpen();
    },
    dialogOpen() {
      this.formDialogStatus = true;
    },
    setCenterCode() {
      console.log("setCetner code... ", this.ifCenterUser);
      //FETCH LOGEND IN USER TYPE CHECK IF IT IS CENTER
      if (this.ifCenterUser) {
        //this.editedItem.center_list_id = this.getAuthUser.center.center_code ;
        this.editedItem.center_list_id = JSON.parse(
          localStorage.getItem("user")
        ).center.id;
        console.log("ifcenter enterd", this.editedItem.center_list_id);
      }
    },

    unSetEditedData() {
      this.editedItem = {
        id: "",
        active: "",
        user_type: 2, // 2 for center
        user_id: "",
        regional_center_id: "",
        center_list_id: "",
        username: "",
        email: "",
        name: "",
        password: "",
        confirmedPassword: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        roleDetails: {
          role_id: 103, // 2 for center
        },
      };
      console.log("unset called ");
      this.setCenterCode();
    },
  },
};
</script>
